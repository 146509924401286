import * as Sentry from '@sentry/browser';
import TokenService from '@services/TokenService';
import { toastAutoHideDuration } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import ClusterResolveLAR from 'pages/ClusterLARDetails';
import TroubleshootingK8SConnector from 'pages/TroubleshootingK8sConnector';
import IntegrationSlack from 'pages/integrations/slack/IntegrationSlack';
import UpgradeTemplatesLandingPage from 'pages/upgrades/templates';
import UpgradeTemplatesRequestTemplate from 'pages/upgrades/templates/create';
import UpgradesTemplateView from 'pages/upgrades/templates/view';
import { lazy, useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
  useSearchParams,
} from 'react-router-dom';
import { SettingsAddToken } from 'pages/Settings/tokens/create';
import AcceptRedirect from 'pages/AcceptInviteRedirect';
import RiskLedgerClustersView from 'pages/risk_ledger/clusters/view_clusters';
import SharcInstructionManual from 'pages/SHARCInstructionManual';

const Login = lazy(() => import('pages/auth/Login'));
const LogoOnlyLayout = lazy(() => import('layouts/LogoOnlyLayout'));
const UnsupportedDevice = lazy(() => import('pages/UnsupportedDevice'));
const Page404 = lazy(() => import('pages/Page404'));
const AcceptInvite = lazy(() => import('pages/AcceptInvite'));
const KBA = lazy(() => import('pages/KBA'));
const NotListedPrivateBeta = lazy(() => import('pages/NotListedPrivateBeta'));
const Feedback = lazy(() => import('pages/Feedback'));

const DashboardLayout = lazy(() => import('layouts/dashboard'));
const AvailabilityRisks = lazy(() => import('pages/AvailabilityRisks'));
const LARDeepDiveView = lazy(() => import('pages/LARDeepDiveView'));
const LARClusterDetails = lazy(() => import('pages/LARClusterDetails'));
const AddCluster = lazy(() => import('pages/add_cluster'));
const UpdateK8sConnector = lazy(() => import('pages/UpdateK8sConnector'));
const ClustersView = lazy(() => import('pages/risk_ledger/clusters'));
const ScannedSignatureDetails = lazy(
  () => import('pages/ClusterScannedSignatureDetails'),
);
const Teams = lazy(() => import('pages/teams/index'));
const Integrations = lazy(() => import('pages/integrations/index'));

const Settings = lazy(() => import('pages/Settings/index'));
const MarketplaceAwsSignUp = lazy(() => import('pages/Marketplace/AwsSignUp'));
const OrganizationSettings = lazy(() => import('pages/OrganizationSettings'));
const ProductSecurity = lazy(() => import('pages/ProductSecurity'));
const ProductConcepts = lazy(() => import('pages/ProductConcepts'));
const ReportAvailabilityRisk = lazy(
  () => import('pages/ReportAvailabilityRisk'),
);
const CloudAccountsIndex = lazy(() => import('pages/cloud_accounts/index'));
const CloudAccountsAddNewAws = lazy(
  () => import('pages/cloud_accounts/edit_aws'),
);
const CloudAccountsAddNewAzure = lazy(
  () => import('pages/cloud_accounts/edit_azure'),
);
const CloudAccountsAddNewGcp = lazy(
  () => import('pages/cloud_accounts/edit_gcp'),
);
const CloudAccountsEdit = lazy(() => import('pages/cloud_accounts/edit'));
const VersionRegisterControlPlaneIndex = lazy(
  () => import('pages/artifact_register/control_plane/index'),
);
const VersionRegisterControlPlaneView = lazy(
  () => import('pages/artifact_register/control_plane/view'),
);
const VersionRegisterAddonsIndex = lazy(
  () => import('pages/artifact_register/addons/index'),
);
const VersionRegisterAddonsView = lazy(
  () => import('pages/artifact_register/addons/view_addon'),
);
const VersionRegisterAddonsViewTag = lazy(
  () => import('pages/artifact_register/addons/view_tag'),
);
const VersionRegisterAddonsViewInstance = lazy(
  () => import('pages/artifact_register/addons/view_instance'),
);
const UpgradesAddonsIndex = lazy(() => import('pages/upgrades/addons/index'));
const UpgradesAddonsView = lazy(() => import('pages/upgrades/addons/view'));
const UpgradesAddonsCreate = lazy(() => import('pages/upgrades/addons/create'));
const UpgradesClustersIndex = lazy(
  () => import('pages/upgrades/clusters/index'),
);
const UpgradesClustersView = lazy(() => import('pages/upgrades/clusters/view'));
const UpgradesClustersCreate = lazy(
  () => import('pages/upgrades/clusters/create'),
);
const ExploreSupportedCloudsAndAddons = lazy(
  () => import('pages/explore/LatestSupportedCloudsAddOns'),
);
const ChkkAgent = lazy(() => import('pages/ChkkAgent'));
const ProductPlansSubscriptions = lazy(() => import('pages/product_plans'));

const TeamsMemberDetails = lazy(
  () => import('pages/teams/components/TeamMemberDetails'),
);

// ----------------------------------------------------------------------

interface ProtectedComponentProps {
  component:
    | ((...params: unknown[]) => JSX.Element | null)
    | (() => JSX.Element | null);

  // Keys can be strings, numbers, or symbols.
  // If you know it to be strings only, you can also restrict it to that.
  // For the value you can use any or unknown,
  // with unknown being the more defensive approach.
  [x: string | number | symbol]: unknown;
}

const ProtectedComponent = ({
  component,
  ...propsForComponent
}: ProtectedComponentProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { orgId } = useParams<{ orgId: string }>();
  const {
    account,
    organizations,
    currentOrganization,
    isAccountLoading,
    isCurrentOrgLoading,
  } = useUserAccountState();

  const { switchOrganization, getChkkUserToken } = TokenService();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const userToken = getChkkUserToken();
  const navigate = useNavigate();

  useEffect(() => {
    const switchOrg = async () => {
      if (
        organizations?.length &&
        currentOrganization?.id &&
        !(isAccountLoading || isCurrentOrgLoading)
      ) {
        if (orgId && currentOrganization?.id !== orgId) {
          const [success, newOrg] = await switchOrganization(orgId);

          if (success && newOrg != undefined) {
            enqueueSnackbar(`Switched to ${newOrg.name} successfully`, {
              variant: 'success',
              autoHideDuration: toastAutoHideDuration,
            });
          } else {
            // switchOrganization() fails if user is not part of the organization
            // in which case, redirect to the current organization
            const accessDeniedMessage = `Access Denied. You are not part of this team. Please contact your team admin to get invited`;
            const redirectMessage = `Redirecting to organization ${currentOrganization?.name}`;
            enqueueSnackbar(accessDeniedMessage, {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            });
            enqueueSnackbar(redirectMessage, {
              variant: 'warning',
              autoHideDuration: toastAutoHideDuration,
            });
            Sentry.captureException(
              `${accessDeniedMessage} ${redirectMessage}`,
              {
                level: 'log',
              },
            );
            navigate('/');
          }
        }
      }
    };
    switchOrg(); // Immediately invoke the async function
  }, [orgId, organizations, currentOrganization?.id]);
  if (account.token || (location.pathname.includes('/accept') && userToken)) {
    const Cp = component;
    return <Cp {...propsForComponent} />;
  } else {
    return (
      <Navigate
        to={`/login?redirect_to=${encodeURI(
          location.pathname + location.search,
        )}`}
      />
    );
  }
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/orgs" replace />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/kba/:id',
      element: <ProtectedComponent component={KBA} />,
    },
    {
      path: '/troubleshoot-k8s-connector',
      element: <ProtectedComponent component={DashboardLayout} />,
    },

    {
      path: '/orgs',
      element: <ProtectedComponent component={DashboardLayout} />,
      children: [
        {
          path: ':orgId',
          children: [
            {
              path: 'subscriptions',
              element: <ProductPlansSubscriptions />,
            },
            {
              path: 'accounts/:accountId',
              children: [
                {
                  path: 'troubleshoot-k8s-connector',
                  element: <TroubleshootingK8SConnector />,
                },
                {
                  path: 'sharc',
                  element: <SharcInstructionManual />,
                },
                {
                  path: 'availability-risks',
                  children: [
                    {
                      index: true,
                      element: <AvailabilityRisks />,
                    },
                    {
                      path: ':larId',
                      element: <LARDeepDiveView />,
                    },
                    {
                      path: ':id/cluster/:clusterId',
                      element: <LARClusterDetails />,
                    },
                  ],
                },
                {
                  path: 'clusters',
                  children: [
                    {
                      index: true,
                      element: <ClustersView />,
                    },
                    {
                      path: 'new',
                      element: <AddCluster />,
                    },
                    {
                      path: ':clusterId',
                      children: [
                        {
                          index: true,
                          element: <RiskLedgerClustersView />,
                        },
                        {
                          path: 'lar/:id',
                          element: <ClusterResolveLAR />,
                        },
                        {
                          path: 'arsig/:id',
                          element: <ScannedSignatureDetails />,
                        },
                        {
                          path: 'update-k8s-connector',
                          element: <UpdateK8sConnector />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'artifact_register',
                  children: [
                    {
                      path: 'control_plane',
                      children: [
                        {
                          index: true,
                          element: <VersionRegisterControlPlaneIndex />,
                        },
                        {
                          path: ':version',
                          element: <VersionRegisterControlPlaneView />,
                        },
                      ],
                    },
                    {
                      path: 'addons',
                      children: [
                        {
                          index: true,
                          element: <VersionRegisterAddonsIndex />,
                        },
                        {
                          path: ':addonId',
                          children: [
                            {
                              index: true,
                              element: <VersionRegisterAddonsView />,
                            },
                            {
                              path: 'version/:version',
                              children: [
                                {
                                  index: true,
                                  element: <VersionRegisterAddonsViewTag />,
                                },
                              ],
                            },
                            {
                              path: ':addonInstanceId',
                              children: [
                                {
                                  index: true,
                                  element: (
                                    <VersionRegisterAddonsViewInstance />
                                  ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'upgrades',
                  children: [
                    {
                      path: 'addons',
                      children: [
                        {
                          index: true,
                          element: <UpgradesAddonsIndex />,
                        },
                        {
                          path: ':upgradeId',
                          element: <UpgradesAddonsView />,
                        },
                        {
                          path: 'new',
                          element: <UpgradesAddonsCreate />,
                        },
                      ],
                    },
                    {
                      path: 'clusters',
                      children: [
                        {
                          index: true,
                          element: <UpgradesClustersIndex />,
                        },
                        {
                          path: ':upgradeId',

                          element: <UpgradesClustersView />,
                        },
                        {
                          path: 'new',
                          element: <UpgradesClustersCreate />,
                        },
                      ],
                    },
                    {
                      path: 'templates',
                      children: [
                        {
                          index: true,
                          element: <UpgradeTemplatesLandingPage />,
                        },
                        {
                          path: ':templateId',

                          element: <UpgradesTemplateView />,
                        },
                        {
                          path: 'new',
                          element: <UpgradeTemplatesRequestTemplate />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'teams',
                  children: [
                    {
                      index: true,
                      element: <Teams />,
                    },
                    {
                      path: 'member/:memberId',
                      element: <TeamsMemberDetails />,
                    },
                  ],
                },
                {
                  path: 'settings',
                  children: [
                    {
                      index: true,
                      element: <Settings />,
                    },
                    {
                      path: 'tokens',
                      children: [
                        {
                          path: 'new',
                          element: <SettingsAddToken />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'integrations',
                  children: [
                    {
                      index: true,
                      element: <Integrations />,
                    },
                    {
                      path: 'slack',
                      element: <IntegrationSlack />,
                    },
                  ],
                },

                {
                  path: 'cloud_accounts',
                  children: [
                    {
                      index: true,
                      element: <CloudAccountsIndex />,
                    },
                    {
                      path: 'new_aws',
                      element: <CloudAccountsAddNewAws />,
                    },
                    {
                      path: 'new_gcp',
                      element: <CloudAccountsAddNewGcp />,
                    },
                    {
                      path: 'new_azure',
                      element: <CloudAccountsAddNewAzure />,
                    },
                    {
                      path: ':id',
                      element: <CloudAccountsEdit />,
                    },
                  ],
                },

                {
                  path: 'explore',
                  children: [
                    {
                      path: 'supported',
                      element: <ExploreSupportedCloudsAndAddons />,
                    },
                  ],
                },
                {
                  path: 'concepts',
                  element: <ProductConcepts />,
                },
                {
                  path: 'security',
                  element: <ProductSecurity />,
                },
                {
                  // TODO: should probably not be under a specific account
                  path: 'organization-settings',
                  element: <OrganizationSettings />,
                },
                {
                  path: 'report-risk',
                  element: <ReportAvailabilityRisk />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/marketplace/aws/subscribe',
      element: <ProtectedComponent component={MarketplaceAwsSignUp} />,
    },
    {
      path: '/feedback',
      element: (
        <LogoOnlyLayout>
          <Feedback />
        </LogoOnlyLayout>
      ),
    },
    {
      path: '/sign-up',
      element: (
        <LogoOnlyLayout>
          <NotListedPrivateBeta />
        </LogoOnlyLayout>
      ),
    },
    {
      path: '/unsupported-device',
      element: (
        <LogoOnlyLayout showLoginBtn={false}>
          <UnsupportedDevice />
        </LogoOnlyLayout>
      ),
    },
    {
      path: '/accept',
      children: [
        {
          index: true,
          element: <ProtectedComponent component={AcceptInvite} />,
        },
        {
          path: ':id',
          element: <ProtectedComponent component={AcceptRedirect} />,
        },
      ],
    },
    {
      path: '/accounts',
      element: <LogoOnlyLayout />,
      children: [{ path: '*', element: <Page404 isDeadRoute={true} /> }],
    },
    {
      path: '/chkkagent',
      element: <ChkkAgent />,
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
